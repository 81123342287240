import { Grid, Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const EventComponent = ({ title, image }) => {
  const EventBox = styled(Box)(({ theme }) => ({
    height: 100,
    objectFit: "contain",
    maxHeight: { xs: "100px", sm: "150px", md: "200px" },
    justifyContent: "center",
    alignContent: "center",
  }));
  return (
    <Grid item xs={12} sm={4} md={2} textAlign={"center"}>
      <EventBox component={"img"} src={image} alt={title} />
      <Typography variant={"h4"} align={"center"}>
        {title}
      </Typography>
    </Grid>
  );
};

export default EventComponent;
