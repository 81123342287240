import "./App.css";
import { Fragment, useEffect, useRef, useState } from "react";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import MainSection from "./sections/MainSection";
import { Box } from "@mui/material";
import EventsSection from "./sections/EventsSection";
import InformationSection from "./sections/InformationSection";
import CarouselSection from "./sections/CarouselSection";
import MapSection from "./sections/MapSection";
import FooterSection from "./sections/FooterSection";
import HashChild from "./components/HashChild";
import { BrowserRouter } from "react-router-dom";
import { HashScroll } from "react-hash-scroll";
import { SECTIONS } from "./constants/Pages";
import FabButton from "./components/FabButton";

const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update state when the target comes into view
        setIsVisible(entry.isIntersecting);
      },
      {
        // Set the threshold to 0.5 so that the target is considered visible when it's 50% in view
        threshold: 0.1,
      }
    );

    // Observe the target element
    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup the observer
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const handleAppBarColor = (visible) => {
    setIsVisible(visible);
  };

  return (
    <Fragment>
      <BrowserRouter>
        <ResponsiveAppBar isVisible={isVisible} />
        <Box ref={ref}>
          <HashScroll hash={`#home`}>
            <HashChild>
              <MainSection handleAppBarVisible={handleAppBarColor} />
            </HashChild>
          </HashScroll>
        </Box>

        <HashScroll hash={`#events`}>
          <HashChild>
            <EventsSection />
          </HashChild>
        </HashScroll>
        <HashScroll hash={`#services`}>
          <HashChild>
            <InformationSection />
          </HashChild>
        </HashScroll>
        <HashScroll hash={`#gallery`}>
          <HashChild>
            <CarouselSection />
          </HashChild>
        </HashScroll>
        <HashScroll hash={`#location`}>
          <HashChild>
            <MapSection />
          </HashChild>
        </HashScroll>
        <HashScroll hash={`#contact`}>
          <HashChild>
            <FooterSection />
          </HashChild>
        </HashScroll>
      </BrowserRouter>
      <FabButton />
    </Fragment>
  );
};

export default App;
