import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";

const CarouselSection = () => {
  const images = require.context("../images/carousel", false, /\.(webp)$/);

  return (
    <Box>
      <Carousel animation="slide" swipe>
        {images.keys().map((item, index) => (
          <Box
            key={index}
            component={"img"}
            src={images(item)}
            height={{ sm: 600, md: 800, lg: 1000 }}
            width={"100%"}
            sx={{
              objectFit: "cover",
            }}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default CarouselSection;
