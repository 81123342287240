import { Fab, Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const FabButton = () => {
  return (
    <Fab
      color={"success"}
      sx={{
        height: 60,
        width: 60,
        position: "fixed",
        right: 20,
        bottom: 20,
      }}
    >
      <Link
        href="https://bit.ly/3T12gGx"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon
          sx={{ height: 40, width: 40, color: "white", paddingTop: 1 }}
        />
      </Link>
    </Fab>
  );
};

export default FabButton;
