import { Box, Grid, Typography } from "@mui/material";
import EventComponent from "../components/EventComponent";

// Events
import Wedding from "../images/events/anillos-de-boda.webp";
import Baptism from "../images/events/bautismo.webp";
import Meetings from "../images/events/cena.webp";
import Babyshower from "../images/events/ducha-de-bebe.webp";
import Birthday from "../images/events/pastel-de-cumpleanos.webp";

const EventsSection = () => {
  const events = [
    {
      title: "Boda",
      image: Wedding,
    },
    {
      title: "Bautizo",
      image: Baptism,
    },
    {
      title: "Reuniones",
      image: Meetings,
    },
    {
      title: "Baby Shower",
      image: Babyshower,
    },
    {
      title: "Cumpleaños",
      image: Birthday,
    },
  ];

  return (
    <Box
      sx={{ backgroundColor: "#FFF2E0" }}
      paddingY={5}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        spacing={5}
      >
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant={"h3"} align={"center"}>
            Eventos
          </Typography>
        </Grid>
        {events.map((event, index) => (
          <EventComponent key={index} title={event.title} image={event.image} />
        ))}
      </Grid>
    </Box>
  );
};

export default EventsSection;
