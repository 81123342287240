import { Box, Grid, IconButton, Stack, Typography, Link } from "@mui/material";
import logo from "../images/logo-black.webp";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { COLORS } from "../constants/Colors";

const FooterSection = () => {
  return (
    <Box>
      <Grid
        container
        justifyContent={"center"}
        sx={{
          backgroundColor: "#fff8e1",
          color: "#ffffff",
          width: "100%",
          display: "flex",
          paddingY: 3,
        }}
      >
        <Grid container item sm={12} md={12} lg={4} spacing={2}>
          <Grid item xs={12} md={12} textAlign={"center"}>
            <Box
              component={"img"}
              src={logo}
              sx={{
                width: { xs: "40%", md: "60%" },
                height: "auto",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="h5"
              sx={{ color: COLORS.body, width: "100%" }}
              textAlign={{ sm: "left", md: "center" }}
            >
              Motolinía 374, <br />
              Guadalajara, Jalisco, México
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="h5" color={COLORS.headers}>
            REDES:
          </Typography>
          <IconButton>
            <Link
              href="https://www.facebook.com/terrazalospalomosofficial"
              underline="none"
            >
              <FacebookIcon
                sx={{ color: COLORS.icon, width: 50, height: 50 }}
              />
            </Link>
          </IconButton>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h5" color={COLORS.headers}>
            TELÉFONOS:
          </Typography>
          <Link href="tel:+523323853334" underline="none" sx={{ marginX: 10 }}>
            <Stack direction="row" spacing={1}>
              <PhoneRoundedIcon sx={{ color: COLORS.icon, marginTop: 0.5 }} />
              <Typography variant="h5" color={COLORS.body}>
                (33) 3323 3334
              </Typography>
            </Stack>
          </Link>
          <Link href="tel:+523323853669" underline="none" sx={{ marginX: 10 }}>
            <Stack direction="row" spacing={1}>
              <PhoneRoundedIcon sx={{ color: COLORS.icon, marginTop: 0.5 }} />
              <Typography variant="h5" color={COLORS.body}>
                (33) 3323 3669
              </Typography>
            </Stack>
          </Link>
          <Link
            href="https://bit.ly/3T12gGx"
            underline="none"
            sx={{ marginX: 10 }}
          >
            <Stack direction="row" spacing={1}>
              <WhatsAppIcon sx={{ color: COLORS.icon, marginTop: 0.5 }} />
              <Typography variant="h5" color={COLORS.body}>
                (33) 3323 3669
              </Typography>
            </Stack>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="h5" color={COLORS.headers}>
            CORREO:
          </Typography>
          <Link href="mailto:reservas@terrazalospalomos.com" underline="none">
            <Stack direction="row" spacing={1}>
              <EmailOutlinedIcon
                sx={{
                  color: COLORS.icon,
                  marginTop: 0.2,
                  width: 30,
                  height: 30,
                }}
              />
              <Typography
                variant="h5"
                color={COLORS.body}
                sx={{ fontSize: "1.3rem" }}
              >
                reservas@terrazalospalomos.com
              </Typography>
            </Stack>
          </Link>
        </Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: "gray",
          paddingLeft: { sm: 5, md: 20 },
          paddingTop: 1,
        }}
      >
        <Typography variant="h6" color={"white"}>
          Made by{" "}
          <Link href="https://alananaya.dev" underline="none" color={"black"}>
            Alan Anaya
          </Link>{" "}
          &copy; {new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterSection;
