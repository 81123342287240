import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CardImage from "../images/carousel/IMG_5768.webp";

const InformationSection = () => {
  const information = [
    "80 Personas",
    "Mesas",
    "Sillas",
    "Mantelería",
    "Brincolín",
    "Área De Juegos Infantiles",
    "Rocola O Bocina Bluetooth",
    "Horario Tope 10:00 P.M.",
  ];
  return (
    <Grid
      container
      padding={5}
      sx={{
        backgroundColor: "lightgray",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Card
        variant="elevation"
        sx={{
          display: "flex",
          width: "100wv",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h4">
              Informes:
            </Typography>
            <List>
              {information.map((info, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={info} />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              height: { xs: 300, sm: 480, md: 520 },
              objectFit: "cover",
            }}
            image={CardImage}
            alt="Live from space album cover"
          />
        </Box>
      </Card>
    </Grid>
  );
};

export default InformationSection;
