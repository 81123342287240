import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import BackgroundImage from "../images/Background.webp";
import Logo from "../images/logo.webp";
import { forwardRef } from "react";

const MainSection = forwardRef((props, ref) => {
  const MainSectionBox = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    flexGrow: 1,
    height: "100vh",
    backgroundPosition: "center",
    top: 0,
  }));

  return (
    <MainSectionBox ref={ref} onScroll={(event) => console.log(event)}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "95vh" }}
      >
        <Grid item textAlign={"center"}>
          <Box
            component={"img"}
            src={Logo}
            height={{ xs: 200, sm: 300, md: 340, lg: 550, xl: 650 }}
          />
          <Typography
            variant="h3"
            fontWeight={200}
            color={"white"}
            fontStyle={"italic"}
          >
            Disfruta con familia y amigos
          </Typography>
        </Grid>
      </Grid>
    </MainSectionBox>
  );
});

export default MainSection;
