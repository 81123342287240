import { Box } from "@mui/material";

const MapSection = () => {
  return (
    <Box
      sx={{
        marginY: 2,
        marginX: { xs: 2, md: 5, lg: 10, xl: 15 },
      }}
    >
      <iframe
        id="ubicacion"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.275038120726!2d-103.3156773!3d20.65838649999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b22da6ccaec7%3A0x577dcb7a8ef73753!2sTERRAZA%20LOS%20PALOMOS!5e0!3m2!1ses-419!2smx!4v1663389021794!5m2!1ses-419!2smx"
        class="map"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        style={{
          border: 0,
          width: "100%",
          height: 650,
          borderRadius: 20,
          boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.40)",
        }}
      />
    </Box>
  );
};

export default MapSection;
